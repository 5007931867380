import React, { useCallback, useEffect, useMemo } from "react";

import DropDownComponent from "legacy/widgets/DropdownWidget/DropdownComponent";
import { CompactMode, EditProps } from "../Constants";
import {
  DropdownCellWrapper,
  extractDropdownOptions,
  Position,
} from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const EditSingleSelectCell = (props: {
  editProps: EditProps;
  value: any;
  inputPosition: Position;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const { value, editProps, inputPosition } = props;
  const {
    handleEditStop,
    editIsRequired,
    editOptions = [],
    transformation,
    onDropdownSearchTextChanged,
    editDropdownClientSideFiltering,
    currentEditDropdownSearchText,
    dropdownOptionsLoading,
  } = editProps;
  const transformedEditOptions = useMemo(() => {
    return extractDropdownOptions(editOptions, transformation);
  }, [editOptions, transformation]);

  const selectedIndex = useMemo(() => {
    return transformedEditOptions.findIndex((opt) => opt.value === value);
  }, [value, transformedEditOptions]);

  const handleValueChange = useCallback(
    (val: any) => {
      handleEditStop({
        shouldSave: true,
        value: val?.value,
        validationErrors: [],
        dropdownOption: { ...val, value: JSON.stringify(val.value) },
      });
    },
    [handleEditStop],
  );
  const handleQueryChanged = useCallback(
    (query: string) => {
      onDropdownSearchTextChanged && onDropdownSearchTextChanged(query);
    },
    [onDropdownSearchTextChanged],
  );

  useEffect(() => {
    return () => {
      if (editDropdownClientSideFiltering) {
        // stop editing w/o saving when component unmounts
        // Note that we don't do this when client side filtering is disabled because
        // the component is usually _expected_ to remount when the enclosing table
        // reloads.
        handleEditStop({ shouldSave: false });
      }
    };
  }, [handleEditStop, editDropdownClientSideFiltering]);

  return (
    <DropdownCellWrapper
      $compactMode={props.compactMode}
      cellProperties={props.cellProps.cellProperties}
      $position={inputPosition}
    >
      <DropDownComponent
        selectionType="SINGLE_SELECT"
        onOptionSelected={handleValueChange}
        selectedIndex={selectedIndex}
        onSearchTextChange={handleQueryChanged}
        clientSideFiltering={editDropdownClientSideFiltering ?? true}
        query={currentEditDropdownSearchText}
        isLoading={dropdownOptionsLoading ?? false}
        options={transformedEditOptions}
        isRequired={editIsRequired}
        widgetId="unused"
        allowClearing={false}
        forceOpen={true}
        onDropdownClosed={() => handleEditStop({ shouldSave: false })}
        width={inputPosition?.width}
        vertical={true}
      />
    </DropdownCellWrapper>
  );
};

export default EditSingleSelectCell;
