import { RepositoryWithEntitiesDto } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { ROOT } from "../../../utils/types";
import { getRepositories } from "../client";
import slice from "../slice";

function* getRepositoriesInternal() {
  const result: RepositoryWithEntitiesDto[] = yield call(getRepositories);
  return result;
}

export const getRepositoriesSaga = createSaga(
  getRepositoriesInternal,
  "getRepositoriesSaga",
  {
    sliceName: "repositories",
  },
);

slice.saga(getRepositoriesSaga, {
  start(state) {
    state.loading[ROOT] = true;
    delete state.errors[ROOT];
  },
  success(state, { payload }) {
    modifyState(state, payload);
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

const modifyState = (
  state: Record<string, any>,
  payload: RepositoryWithEntitiesDto[],
): void => {
  state.entities = payload.reduce((result, repo) => {
    result[repo.id] = repo;
    return result;
  }, {} as Record<string, RepositoryWithEntitiesDto>);
};
