import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as LayoutFixedIcon } from "assets/icons/widgets/canvas-fixedgrid.svg";
import { ReactComponent as LayoutVerticalIcon } from "assets/icons/widgets/canvas-vstack.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";
import { CanvasLayout } from "legacy/constants/WidgetConstants";

const LayoutIconsWrapper = styled(IconWrapper)<IconProps>`
  svg {
    ${(props) =>
      !props.keepColors &&
      css`
        path {
          stroke: ${props.color || props.theme.colors.GREY_500};
        }
      `}
  }
`;

export function getLayoutIcon(layout: CanvasLayout | undefined) {
  let Icon: React.FC<React.PropsWithChildren<any>>;

  switch (layout) {
    case CanvasLayout.VSTACK:
      Icon = LayoutVerticalIcon;
      break;
    case CanvasLayout.HSTACK:
      Icon = LayoutVerticalIcon;
      break;
    default:
      Icon = LayoutFixedIcon;
      break;
  }

  return <LayoutIconsWrapper>{Icon && <Icon />}</LayoutIconsWrapper>;
}
