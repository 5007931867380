import { noop } from "lodash";
import React, { forwardRef, Ref } from "react";
import styled from "styled-components";
import { ReactComponent as BillingIcon } from "legacy/assets/icons/ads/billing.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/ads/close.svg";
import { ReactComponent as ContextMenuIcon } from "legacy/assets/icons/ads/context-menu.svg";
import { ReactComponent as CreateNewIcon } from "legacy/assets/icons/ads/create-new.svg";
import { ReactComponent as DeleteIcon } from "legacy/assets/icons/ads/delete.svg";
import { ReactComponent as DownArrow } from "legacy/assets/icons/ads/down_arrow.svg";
import { ReactComponent as DuplicateIcon } from "legacy/assets/icons/ads/duplicate.svg";
import { ReactComponent as EditIcon } from "legacy/assets/icons/ads/edit.svg";
import { ReactComponent as ErrorIcon } from "legacy/assets/icons/ads/error.svg";
import { ReactComponent as ExternalLinkIcon } from "legacy/assets/icons/ads/external-link.svg";
import { ReactComponent as GeneralIcon } from "legacy/assets/icons/ads/general.svg";
import { ReactComponent as InviteUserIcon } from "legacy/assets/icons/ads/invite-users.svg";
import { ReactComponent as RocketIcon } from "legacy/assets/icons/ads/launch.svg";
import { ReactComponent as LogoutIcon } from "legacy/assets/icons/ads/logout.svg";
import { ReactComponent as ManageIcon } from "legacy/assets/icons/ads/manage.svg";
import { ReactComponent as SearchIcon } from "legacy/assets/icons/ads/search.svg";
import { ReactComponent as ShareIcon } from "legacy/assets/icons/ads/share.svg";
import { ReactComponent as SuccessIcon } from "legacy/assets/icons/ads/success.svg";
import { ReactComponent as UserIcon } from "legacy/assets/icons/ads/user.svg";
import { ReactComponent as ViewAllIcon } from "legacy/assets/icons/ads/view-all.svg";
import { ReactComponent as WarningIcon } from "legacy/assets/icons/ads/warning.svg";
import { ReactComponent as WorkspaceIcon } from "legacy/assets/icons/ads/workspace.svg";
import { ReactComponent as CopyIcon } from "legacy/assets/icons/control/copy.svg";
import { IconSize, sizeHandler } from "../utils/IconUtil";
import Spinner from "./Spinner";
import { CommonComponentProps, Classes } from "./common";

export { IconSize };

const IconCollection = [
  "delete",
  "user",
  "general",
  "billing",
  "edit",
  "error",
  "success",
  "search",
  "close",
  "share",
  "rocket",
  "workspace",
  "plus",
  "invite-user",
  "view-all",
  "warning",
  "downArrow",
  "context-menu",
  "duplicate",
  "logout",
  "manage",
  "copy",
  "external-link",
] as const;

type IconName = (typeof IconCollection)[number];

const IconWrapper = styled.span<IconProps>`
  &:focus {
    outline: none;
  }
  display: flex;
  align-items: center;
  svg {
    width: ${(props) => sizeHandler(props.size, props.theme)}px;
    height: ${(props) => sizeHandler(props.size, props.theme)}px;
    path {
      fill: ${(props) => props.theme.legacy.colors.icon.normal};
    }
  }
  ${(props) => (props.invisible ? `visibility: hidden;` : null)};

  &:hover {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.legacy.colors.icon.hover};
    }
  }

  &:active {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.legacy.colors.icon.active};
    }
  }
`;

type IconProps = {
  size?: IconSize;
  name?: IconName;
  invisible?: boolean;
  className?: string;
  onClick?: () => void;
};

const Icon = forwardRef(
  (props: IconProps & CommonComponentProps, ref: Ref<HTMLSpanElement>) => {
    let returnIcon;
    switch (props.name) {
      case "delete":
        returnIcon = <DeleteIcon />;
        break;
      case "user":
        returnIcon = <UserIcon />;
        break;
      case "general":
        returnIcon = <GeneralIcon />;
        break;
      case "billing":
        returnIcon = <BillingIcon />;
        break;
      case "edit":
        returnIcon = <EditIcon />;
        break;
      case "error":
        returnIcon = <ErrorIcon />;
        break;
      case "success":
        returnIcon = <SuccessIcon />;
        break;
      case "search":
        returnIcon = <SearchIcon />;
        break;
      case "close":
        returnIcon = <CloseIcon />;
        break;
      case "downArrow":
        returnIcon = <DownArrow />;
        break;
      case "share":
        returnIcon = <ShareIcon />;
        break;
      case "rocket":
        returnIcon = <RocketIcon />;
        break;
      case "workspace":
        returnIcon = <WorkspaceIcon />;
        break;
      case "plus":
        returnIcon = <CreateNewIcon />;
        break;
      case "invite-user":
        returnIcon = <InviteUserIcon />;
        break;
      case "view-all":
        returnIcon = <ViewAllIcon />;
        break;
      case "context-menu":
        returnIcon = <ContextMenuIcon />;
        break;
      case "duplicate":
        returnIcon = <DuplicateIcon />;
        break;
      case "logout":
        returnIcon = <LogoutIcon />;
        break;
      case "manage":
        returnIcon = <ManageIcon />;
        break;
      case "warning":
        returnIcon = <WarningIcon />;
        break;
      case "copy":
        returnIcon = <CopyIcon />;
        break;
      case "external-link":
        returnIcon = <ExternalLinkIcon />;
        break;
      default:
        returnIcon = null;
        break;
    }
    return returnIcon && !props.isLoading ? (
      <IconWrapper
        className={Classes.ICON}
        ref={ref}
        {...props}
        onClick={props.onClick || noop}
      >
        {returnIcon}
      </IconWrapper>
    ) : props.isLoading ? (
      <Spinner size={props.size} />
    ) : null;
  },
);

Icon.displayName = "Icon";

export default Icon;
