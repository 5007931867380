import { RegisteredComponents, Typographies } from "@superblocksteam/shared";
import { max, min } from "lodash";
import { XYCoord } from "react-dnd";
import envs from "env";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { GeneratedTheme } from "legacy/themes/types";
import PerformanceTracker, {
  PerformanceName,
} from "legacy/utils/PerformanceTracker";
import { isDev } from "./env";
import { sendWarningUINotification } from "./notification";

export enum GetDropTargetMessageTypes {
  GET = "getDropTarget",
  GET_RESULT = "getDropTargetResult",
}

export type MessageTypeToPayload = {
  "iframe-action": ReduxAction<unknown>;
  "iframe-action-batch": ReduxAction<unknown>[];
  "parent-action": ReduxAction<unknown>;
  "parent-action-batch": ReduxAction<unknown>[];
  click: void;
  ready: void;
  "parent-ready": void;
  init: {
    redux: Record<string, any>;
    logContext: Record<string, any>;
    keysToRegister?: Array<{ keys: string; options?: any }>;
  };
  keypress: { keys: string };
  "register-custom-components": {
    components: RegisteredComponents;
  };
  "register-keypress": { keys: string; options?: any };
  "filepicker-singleton": Record<string, Record<string, File>>;
  "canvas-scale-update": { canvasScaleFactor: number };
  "resolve-promise": void;
  "reject-promise": void;
  "reload-configs": void;
  "hot-reloaded": void;
  dragstart: void;
  "flash-element": { elementId: string };
  "navigate-to": { url: string; newWindow?: boolean };
  "set-theme": {
    theme: GeneratedTheme;
    updateRedux?: boolean;
    storedTypographies?: Typographies;
  };
  [GetDropTargetMessageTypes.GET]: { parentId: string };
  [GetDropTargetMessageTypes.GET_RESULT]: {
    offset: XYCoord;
    size: { height: number; width: number };
  };
  "open-widget-context-menu": {
    widgetId: string;
    clientX: number;
    clientY: number;
    pasteAtCursorInsertionIndexes?: {
      sectionInsertionPosition?: number;
      columnInsertionPosition?: number;
      stackInsertionPosition?: number;
    };
  };
};
type MessageTypes = keyof MessageTypeToPayload;

export function isEmbeddedBySuperblocksFirstParty() {
  return (
    window.location.origin + "/" === envs.get("SUPERBLOCKS_UI_IFRAME_URL") &&
    window.location.pathname.startsWith("/applications/iframe") &&
    window !== window.parent
  );
}

function isParentApp() {
  return (
    (window.location.pathname.startsWith("/applications/") &&
      !window.location.pathname.startsWith("/applications/iframe")) ||
    window.location.pathname.startsWith("/embed/applications/")
  );
}

// TODO: window.opener for 'open in new tab' feature
const PARENT_REF = window !== window.parent ? window.parent : null;

let childRef: Window | null = null;

export function isChildRegistered() {
  return Boolean(childRef);
}

function childMessageHandler(e: MessageEvent) {
  // Make sure the message is coming from the iframe we explicitly created
  if (e.source !== childRef) return;

  const event = new SbIframeEventInternal(e.data.type as MessageTypes, e.data);
  iframeMessageHandler.dispatchEvent(event);
}

function parentMessageHandler(e: MessageEvent) {
  // Make sure the message is coming from the iframe we explicitly created
  if (e.source !== PARENT_REF) return;

  const event = new SbIframeEventInternal(e.data.type as MessageTypes, e.data);
  iframeMessageHandler.dispatchEvent(event);
}

if (isEmbeddedBySuperblocksFirstParty() && PARENT_REF) {
  window.addEventListener("message", parentMessageHandler);
}

export function setChildRef(ref: Window) {
  childRef = ref;
  window.addEventListener("message", childMessageHandler);
}

export function getChildRef() {
  return childRef;
}

export function unregisterIframeRef() {
  window.removeEventListener("message", childMessageHandler);
  childRef = null;
}

export function sendMessage<MT extends MessageTypes>(
  message: {
    type: MT;
    payload?: MessageTypeToPayload[MT];
    [key: string]: any;
  },
  options?: {
    // For when you have debounced the sendMessage and want better stats
    overrideStartTime: number;
  },
) {
  if (!PARENT_REF && !childRef) return;

  if (isEmbeddedBySuperblocksFirstParty()) {
    if (!PARENT_REF) throw new Error("Parent is not set");
    try {
      PARENT_REF.postMessage(
        {
          ...message,
          startTime: options?.overrideStartTime ?? +new Date(),
        },
        "*",
      );
    } catch (e) {
      console.error("Error sending message to parent", message);
      // This middleware is used to prevent new serialization bugs from being introduced
      if (isDev()) {
        sendWarningUINotification({
          message: `Redux actions must be serializable (dev warning) type: ${message.type}`,
          duration: 5,
        });
      }
    }
  } else if (isParentApp() && childRef) {
    try {
      childRef.postMessage(
        {
          ...message,
          startTime: options?.overrideStartTime ?? +new Date(),
        },
        "*",
      );
    } catch (e) {
      console.error("Error sending message to child", message);
      // This middleware is used to prevent new serialization bugs from being introduced
      if (isDev()) {
        sendWarningUINotification({
          message: `Redux actions must be serializable (dev warning) type: ${message.type}`,
          duration: 5,
        });
      }
    }
  }
}

let shouldReportStats = false;
const OVERHEAD: number[] = [];
const ACTIONS: string[] = [];
let lastReportedOverheadLength = 0;
export function setStatsReporting(newValue: boolean) {
  shouldReportStats = newValue;
}
export function updateStats(type: string, startTime: number) {
  const now = Date.now();
  ACTIONS.push(type);
  OVERHEAD.push(now - startTime);
  PerformanceTracker.track(PerformanceName.POSTMESSAGE, {
    duration: OVERHEAD[OVERHEAD.length - 1],
    messageType: type,
  });
  if (shouldReportStats) {
    console.debug(
      "[] stats",
      isParentApp() ? "parent" : "child",
      type,
      `${OVERHEAD[OVERHEAD.length - 1].toFixed(1)}ms`,
    );
  }
}
export function reportStats() {
  if (shouldReportStats && lastReportedOverheadLength !== OVERHEAD.length) {
    const avgT = OVERHEAD.reduce((a, b) => a + b, 0) / OVERHEAD.length;
    const minT = min(OVERHEAD);
    const maxT = max(OVERHEAD);
    console.group("[] stats summary", isParentApp() ? "parent" : "child");
    console.log("avg", avgT.toFixed(1), "ms");
    console.log("min", minT?.toFixed(1), "ms");
    console.log("max", maxT?.toFixed(1), "ms");
    console.log("count", OVERHEAD.length);
    console.groupEnd();
    lastReportedOverheadLength = OVERHEAD.length;
  }
}

class SbIframeEventInternal<T = Record<string, any>> extends Event {
  data: { payload: T; startTime: number; [key: string]: any };
  constructor(
    type: MessageTypes,
    data: { payload: T; startTime: number; [key: string]: any },
  ) {
    super(type);
    this.data = data;
  }
}

// We are using a custom event target so that you each of our custom postMessage types
// is a separate handler
interface MessageHandler extends EventTarget {
  addEventListener<MT extends MessageTypes>(
    type: MT,
    callback: IframeEventHandler<MT>,
  ): void;
  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
  ): void;
  removeEventListener<MT extends MessageTypes>(
    type: MT,
    callback: IframeEventHandler<MT>,
  ): void;
  removeEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
  ): void;
}

// This is a hack to get around the fact that we can't overload the addEventListener method
class SbMessageHandler extends (EventTarget as {
  new (): MessageHandler;
  prototype: MessageHandler;
}) {
  // Parent implements all functionality
}

export type IframeEventHandler<MT extends MessageTypes> = (
  event: SbIframeEventInternal<MessageTypeToPayload[MT]>,
) => void;

export const iframeMessageHandler = new SbMessageHandler();
