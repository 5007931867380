import * as Sentry from "@sentry/react";
import "tracing/config";
import React, { useState, useEffect } from "react";
import { DndProvider, DndProviderProps } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Slide } from "react-toastify";
import { ThemeProvider, DefaultTheme } from "styled-components";
import envs from "env";
import AppErrorBoundary from "legacy/AppErrorBoundary";
import { StyledToastContainer } from "legacy/components/ads/Toast";
import { LayersContext, Layers } from "legacy/constants/Layers";
import store from "store";
import { setStore } from "store/dynamic";
import {
  AntdSelectStyles,
  BlueprintSelectStyles,
  BlueprintTooltipStyles,
  AntdDropdownStyles,
  AntdNoPaddingDropdownStyles,
  AntdNoPaddingPopoverStyles,
  AntdTableRowStyle,
  AntdTooltipStyles,
  AntdCollapseStyles,
  AntDPlatformNotificatonStyles,
  BlueprintPopoverStyles,
  AntModalStyles,
  TinyMceStyles,
  AntdPopconfirmStyles,
  AntdButtonStyles,
  AntdDatePickerStyles,
  AntdBreadCrumbStyles,
  AntdAlertStyles,
} from "styles/globals";

import "./index.less";
import "./config";

import { isDev } from "utils/env";
import AppRouter from "./router";
import getTheme from "./styles/theme";

// Sets the store dynamically so it can be accessed in saga modules
// without a circular dependency
setStore(store as any);

const DndProviderCustom = DndProvider as React.FC<
  React.PropsWithChildren<DndProviderProps<any, any>>
>;
const DndOptions = {
  enableMouseEvents: true,
  enableHoverOutsideTarget: true,
  touchSlop: 20,
};

const ThemedApp = () => {
  const [theme, setTheme] = useState<DefaultTheme>();

  useEffect(() => {
    getTheme().then((theme) => {
      setTheme(theme);
    });
  }, []);

  return theme ? (
    <ThemeProvider theme={theme}>
      <BlueprintPopoverStyles />
      <BlueprintTooltipStyles />
      <BlueprintSelectStyles />
      <BlueprintTooltipStyles />
      <AntdDropdownStyles />
      <AntdSelectStyles />
      <AntdNoPaddingDropdownStyles />
      <AntdNoPaddingPopoverStyles />
      <AntdDatePickerStyles />
      <AntdTableRowStyle />
      <AntdPopconfirmStyles />
      <AntdButtonStyles />
      <AntdTooltipStyles />
      <AntModalStyles />
      <AntdTooltipStyles />
      <AntDPlatformNotificatonStyles />
      <AntdCollapseStyles />
      <AntdBreadCrumbStyles />
      <AntdAlertStyles />
      <TinyMceStyles />
      <StyledToastContainer
        hideProgressBar
        draggable={false}
        transition={Slide}
        autoClose={5000}
        closeButton={false}
        pauseOnHover={false}
      />
      <AppErrorBoundary>
        <AppRouter />
      </AppErrorBoundary>
    </ThemeProvider>
  ) : null;
};

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<span>An error has occured</span>}>
      <Provider store={store}>
        <DndProviderCustom backend={TouchBackend} options={DndOptions}>
          <LayersContext.Provider value={Layers}>
            <ThemedApp />
          </LayersContext.Provider>
        </DndProviderCustom>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

createRoot(document.getElementById("root") as Element).render(<App />);

// expose store when run in Cypress/Playwright or dev mode locally
if (
  (window as any).Cypress ||
  (window as any).playwright ||
  (isDev() &&
    ["localhost", "127.0.0.1", "[::1]"].includes(window.location.hostname))
) {
  (window as any).store = store;
}

// Required to be set in the entry point so that all dynamic imports work
__webpack_public_path__ = envs.get("SUPERBLOCKS_UI_ASSET_URL");
