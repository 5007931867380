import { findIndex } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";

import { usePointerDownOutside } from "hooks/ui";
import DropDownComponent from "legacy/widgets/DropdownWidget/DropdownComponent";
import { CompactMode, EditProps } from "../Constants";
import {
  extractDropdownOptions,
  Position,
  DropdownCellWrapper,
} from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const EditMultiSelectCell = (props: {
  editProps: EditProps;
  value: any;
  inputPosition: Position;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const dropdownRef = useRef(null);
  const { editProps, inputPosition, cellProps } = props;
  const {
    currentEditValue,
    handleEditStop,
    handleEditChange,
    editIsRequired,
    editOptions = [],
    transformation,
    onDropdownSearchTextChanged,
    editDropdownClientSideFiltering,
    currentEditDropdownSearchText,
    dropdownOptionsLoading,
  } = editProps;

  const selectedOptions = useMemo(() => {
    return Array.isArray(currentEditValue)
      ? currentEditValue
      : currentEditValue != null
      ? [currentEditValue]
      : [];
  }, [currentEditValue]);

  const transformedEditOptions = useMemo(() => {
    return extractDropdownOptions(editOptions, transformation);
  }, [editOptions, transformation]);

  const selectedIndexArr = useMemo(() => {
    return selectedOptions
      ? selectedOptions
          .map((o) => findIndex(transformedEditOptions, { value: o }))
          .filter((ind) => ind !== -1)
      : [];
  }, [selectedOptions, transformedEditOptions]);

  const handleOptionSelected = useCallback(
    (selectedOption: any) => {
      if (selectedOption) {
        const isAlreadySelected = selectedOptions.includes(
          selectedOption.value,
        );
        if (!isAlreadySelected) {
          handleEditChange(
            [...selectedOptions, selectedOption.value],
            [
              {
                label: selectedOption.label,
                value: JSON.stringify(selectedOption.value),
              },
            ],
          );
        } else {
          handleEditChange(
            selectedOptions.filter(
              (opt) => opt !== selectedOption.value,
              [selectedOption],
            ),
          );
        }
      }
    },
    [handleEditChange, selectedOptions],
  );

  const handleOptionRemoved = useCallback(
    (removedIndex: any) => {
      const newSelectedValues = selectedOptions.filter(
        (value: string) =>
          findIndex(transformedEditOptions, { value }) !== removedIndex,
      );
      handleEditChange(newSelectedValues);
    },
    [handleEditChange, selectedOptions, transformedEditOptions],
  );

  const handleSelectAll = useCallback(
    (options: Array<{ value: string; label: string }>) => {
      handleEditChange(
        options.map(({ value }) => value),
        options.map((val) => ({ ...val, value: JSON.stringify(val.value) })),
      );
    },
    [handleEditChange],
  );

  const handleQueryChanged = useCallback(
    (query: string) => {
      onDropdownSearchTextChanged && onDropdownSearchTextChanged(query);
    },
    [onDropdownSearchTextChanged],
  );

  const handleDropdownClose = useCallback(() => {
    const validationErrors =
      selectedOptions.length === 0 && editIsRequired
        ? ["This field is required"]
        : [];
    handleEditStop({
      shouldSave: true,
      value: selectedOptions,
      validationErrors,
    });
  }, [handleEditStop, selectedOptions, editIsRequired]);

  usePointerDownOutside({
    onClickOutside: handleDropdownClose,
    wrapperRefs: [dropdownRef],
    wrapperSelectors: [".bp5-multi-select-popover"],
  });

  return (
    <DropdownCellWrapper
      $position={inputPosition}
      $compactMode={props.compactMode}
      ref={dropdownRef}
      cellProperties={cellProps.cellProperties}
    >
      <DropDownComponent
        selectionType="MULTI_SELECT"
        onOptionSelected={handleOptionSelected}
        onOptionRemoved={handleOptionRemoved}
        onSearchTextChange={handleQueryChanged}
        clientSideFiltering={editDropdownClientSideFiltering ?? true}
        query={currentEditDropdownSearchText}
        onSelectAll={handleSelectAll}
        selectedIndexArr={selectedIndexArr}
        isLoading={dropdownOptionsLoading ?? false}
        options={transformedEditOptions}
        isRequired={editIsRequired}
        widgetId="unused"
        allowSelectAll={true}
        forceOpen={true}
        width={inputPosition?.width}
        vertical={true}
        height={inputPosition?.height ?? 40}
      />
    </DropdownCellWrapper>
  );
};

export default EditMultiSelectCell;
